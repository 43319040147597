<template>
  <div class="container">
  </div>
</template>

<script>

export default {
  name: "FavoritesRenters",
  created() {
    this.$router.push({name: 'FavoriteRentersApartments'});
  }
};
</script>
